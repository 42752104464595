import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { IoIosPause } from "react-icons/io";
import { IoPlay } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TicketSupportContext from "../TicketSupport/components/TicketSupportContext";
import { getAuthToken, authToken } from "../../../utils/auth";
import { baseUrl } from "utils/domain";
const ScheduleNotification = () => {
  const [reRenderState, setRerenderState] = useState(false);
  const [scheduledNotification, setScheduledNotification] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newDate, setNewDate] = useState("");
  const [newTime, setNewTime] = useState("00:00");
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const value = useContext(TicketSupportContext);
  const { setNotificationData } = value;
  const navigate = useNavigate();
  const token = getAuthToken();
  const apiToken = authToken();
  const headers = {
    Authorization: `Bearer ${apiToken}`,
  };
  useEffect(() => {
    axios.get(`${baseUrl}/get-scheduled-notification`, { headers }).then((res) => {
        const sortedNotifications = res.data.sort(
          (a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime)
        );
        setScheduledNotification(sortedNotifications);
      });
  }, [reRenderState]);
  const handleEditBtnClick = (item) => {
    setNotificationData(item);
    navigate("/admin/notification");
  };
  const handleDeleteBtnClick = (item) => {
    axios.delete(`${baseUrl}/delete-notification/${item._id}`, { headers }).then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setRerenderState(!reRenderState);
        }
      });
  };
  const handlePlyPusBtnClick = (item) => {
    const newStatus = !item.isActive;
    const scheduledDate = new Date(item.scheduledTime);
    if (newStatus && scheduledDate < new Date()) {
      setCurrentItem(item);
      setShowPopup(true);
      setNewDate(item.scheduledTime.split("T")[0]);
      setNewTime(item.scheduledTime.split("T")[1].split(".")[0]);
    } else {
      updateStatus(item._id, newStatus);
    }
  };
  const updateStatus = (id, isActive, scheduledTime = null) => {
    const data = { isActive };
    if (scheduledTime) data.scheduledTime = scheduledTime;
    axios.put(`${baseUrl}/update-status-notification/${id}`, data, { headers }).then((res) => {
        if (res.status === 200) {
          setRerenderState(!reRenderState);
          toast.success("Status updated successfully");
        }
      }).catch((error) => {
        toast.error("Error updating status");
      });
  };
  const handleSaveNewDate = () => {
    if (currentItem && newDate && newTime) {
      const updatedScheduledTime = new Date(
        `${newDate}T${newTime}`
      ).toISOString();
      updateStatus(currentItem._id, true, updatedScheduledTime);
      setShowPopup(false);
      setCurrentItem(null);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <>
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">No.</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Date</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Time</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Title</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Description</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Image</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Action</th>
          </tr>
        </thead>
        <tbody>
          {scheduledNotification.map((item, index) => (
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800" key={index}>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">{index + 1}</td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">{formatDate(item.scheduledTime)}</td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">{item.scheduledTime.split("T")[1].split(".")[0]}</td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">{item.title}</td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">{item.Description} </td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                {item.image ? 
                <img
                  src={`${baseUrl + "/" + item.image}`}
                  alt=""
                  className="h-12 w-12 cursor-pointer rounded-md"
                  onClick={() =>handleImageClick(`${baseUrl + "/" + item.image}`)}
                  /> : "-"
                  }
              </td>
              {token.permissions.find((p) => p.name === "Notification")?.permissions.fullAccess && (
                <td className="flex gap-2 px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  <MdModeEdit onClick={() => handleEditBtnClick(item)} className="cursor-pointer" />
                  <MdDelete onClick={() => handleDeleteBtnClick(item)} className="cursor-pointer" />
                  {item.isActive ? (
                    <IoIosPause onClick={() => handlePlyPusBtnClick(item)} />
                  ) : (
                    <IoPlay onClick={() => handlePlyPusBtnClick(item)} className="cursor-pointer" />
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
      {showPopup && (
        <div className="popup fixed top-1/2 left-1/2 z-50 h-64 w-96 -translate-x-1/2 -translate-y-1/2 transform rounded bg-blue-50 p-4 pt-6 text-center shadow-lg">
          <h3 className="pb-6">Scheduled Time is in the past</h3>
          <input
            type="date"
            value={newDate}
            onChange={(e) => setNewDate(e.target.value)}
            className="w-64 rounded border p-2"
            min={getCurrentDate()}
          />
          <input
            type="time"
            value={newTime}
            onChange={(e) => setNewTime(e.target.value)}
            className="mt-4 w-64 rounded border p-2"
          />
          <br />
          <div className="mt-4">
            <button onClick={handleSaveNewDate} className="mt-2 rounded bg-blue-500 py-1 px-10 text-white" > Save </button>
            <button onClick={() => setShowPopup(false)} className="mt-2 ml-2 rounded bg-red-500 py-1 px-10 text-white">Cancel</button>
          </div>
        </div>
      )}
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative rounded-md bg-white p-4">
            <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-700" onClick={closeModal}>&times;</button>
            <img src={selectedImage} alt="Enlarged" className="h-96 w-96 rounded-lg" />
          </div>
        </div>
      )}
    </>
  );
};
export default ScheduleNotification;