import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { authToken, getAuthToken } from "utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoPlus } from "react-icons/go";
import { baseUrl } from "../../../utils/domain";
import { MdModeEditOutline } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
const Subscription = () => {
  const apiToken = authToken();
  const headers = {
    Authorization: `Bearer ${apiToken}`,
  };
  const [couponData, setCouponData] = useState([]);
  const token = getAuthToken();
  const [editItem, setEditItem] = useState(null);
  const [couponTitleToggle, setCouponTitleToggle] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const [submitState,setSubmitState] = useState(false)
  const [viewState,setViewState] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const onSubmit = (data, close) => {
    const formData = new FormData();
    for (let i = 1; i <= 4; i++) {
      if (data[`image${i}`]?.[0]) {
        formData.append(`image${i}`, data[`image${i}`][0]);
      }
    }
    formData.append("threeMonthPrice", data.threeMonthPrice);
    formData.append("yearlyPrice", data.yearlyPrice);
    formData.append("point1", data.point1);
    formData.append("point2", data.point2);
    formData.append("point3", data.point3);
    formData.append("point4", data.point4);
    formData.append("threeMonthDiscountAmount", data.threeMonthDiscountAmount);
    formData.append("yearlyDiscountAmount", data.yearlyDiscountAmount);
    formData.append("monthTitle", data.monthTitle);
    formData.append("yearTitle", data.yearTitle);
    
    if (editItem) {
      axios.put(`${baseUrl}/update-subscriptions/${editItem._id}`, formData, {headers}).then((res) => {
          toast.success("Subscription updated successfully");
          setRenderComponent(!renderComponent);
          setEditItem(null);
          reset();
          close();
        }).catch(() => {
          toast.error("Failed to update subscription");
        });
    } else {
      axios.post(`${baseUrl}/subscriptions`, formData, {headers}).then((res) => {
          toast.success(res.data.message);
          setRenderComponent(!renderComponent);
          setEditItem(null);
          reset();
          close();
        }).catch(() => {
          toast.error("Failed to create subscription");
        });
    }
  };
  useEffect(() => {
    axios.get(`${baseUrl}/get-subscription`, { headers }).then((res) => {
      setCouponData(res.data);
    });
  }, [renderComponent]);
  const handleEditBtnClick = (item) =>{
    setCouponTitleToggle(true);
    setEditItem(item);
    setValue("threeMonthPrice", item.threeMonthPrice);
    setValue("yearlyPrice", item.yearlyPrice);
    setValue("point1",item.point1);
    setValue("point2", item.point2);
    setValue("point3", item.point3);
    setValue("point4", item.point4);
    setValue("monthTitle", item.monthTitle);
    setValue("yearTitle", item.yearTitle);
    setValue("threeMonthDiscountAmount", item.monthlyDiscountAmount);
    setValue("yearlyDiscountAmount", item.yearlyDiscountAmount);
  }
  const handleViewBtnClick = (item) => {
    setCouponTitleToggle(true);
    setEditItem(item);
    setSubmitState(true)
    setValue("monthTitle", item.monthTitle);
    setValue("yearTitle", item.yearTitle);
    setValue("threeMonthPrice", item.threeMonthPrice);
    setValue("yearlyPrice", item.yearlyPrice);
    setValue("point1", item.point1);
    setValue("point2", item.point2);
    setValue("point3", item.point3);
    setValue("point4", item.point4);
    setValue("threeMonthDiscountAmount", item.monthlyDiscountAmount);
    setValue("yearlyDiscountAmount", item.yearlyDiscountAmount);
    setViewState(true)
  };
  return (
    <div className="top-4 overflow-x-auto">
      {token.permissions.find((p) => p.name === "Coupon")?.permissions
        .fullAccess && (
        <div className="flex justify-end">
          <Popup
            open={!!editItem}
            trigger={
              !couponData.length ? (
                <button
                  type="button"
                  className="my-11 mb-2 flex items-center gap-4 rounded-lg bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 px-5 py-2.5 text-center text-sm font-medium text-white me-2"
                >
                  <GoPlus /> Create Subscription
                </button>
              ) : null
            }
            modal
            nested
          >
            {(close) => (
              <div className="h-full w-full rounded-lg bg-white p-4 shadow-lg">
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
                  onClick={() => {
                    setEditItem(null);
                    setCouponTitleToggle(false);
                    close();
                    setSubmitState(false);
                    setViewState(false);
                  }}
                >
                  &times;
                </button>
                <h2 className="py-4 text-center text-4xl dark:text-white">
                  {viewState == true
                    ? "View Subscription"
                    : couponTitleToggle
                    ? "Update Subscription"
                    : "Create Subscription"}
                </h2>
                <form
                  className="w-full"
                  onSubmit={handleSubmit((data) => onSubmit(data, close))}
                >
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter Month Title
                      </label>
                      <input
                        type="text"
                        {...register("monthTitle")}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Month Title"
                      />
                      {errors.monthTitle && (
                        <span className="text-red-600">Enter Month Title</span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter Yearly Price
                      </label>
                      <input
                        type="text"
                        {...register("yearTitle")}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Yearly Price"
                      />
                      {errors.yearTitle && (
                        <span className="text-red-600">Enter Yearly Price</span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter 3 months Price
                      </label>
                      <input
                        type="number"
                        {...register("threeMonthPrice", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter  3 months Price"
                      />
                      {errors.threeMonthPrice && (
                        <span className="text-red-600">
                          Enter 3 months Price
                        </span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter Yearly Price
                      </label>
                      <input
                        type="number"
                        {...register("yearlyPrice", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Yearly Price"
                      />
                      {errors.yearlyPrice && (
                        <span className="text-red-600">Enter Yearly Price</span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter 3 months Discount
                      </label>
                      <input
                        type="number"
                        {...register("threeMonthDiscountAmount", {
                          required: true,
                          validate: (value) =>
                            value <= getValues("threeMonthPrice") ||
                            "Discount cannot be greater than the price",
                        })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter  3 months Discount price"
                      />
                      {errors.threeMonthDiscountAmount && (
                        <span className="text-red-600">
                          {errors.threeMonthDiscountAmount.message ||
                            "Enter 3 months Discount"}
                        </span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Enter Yearly Discount
                      </label>
                      <input
                        type="number"
                        {...register("yearlyDiscountAmount", {
                          required: true,
                          validate: (value) =>
                            value <= getValues("yearlyPrice") ||
                            "Discount cannot be greater than the price",
                        })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Yearly Discount"
                      />
                      {errors.yearlyDiscountAmount && (
                        <span className="text-red-600">
                          {errors.yearlyDiscountAmount.message ||
                            "Enter yearly Discount"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Description 1
                      </label>
                      <input
                        type="text"
                        {...register("point1", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Description 1"
                      />
                      {errors.point1 && (
                        <span className="text-red-600">
                          Enter Description 1
                        </span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Description 2
                      </label>
                      <input
                        type="text"
                        {...register("point2", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Description 2"
                      />
                      {errors.point2 && (
                        <span className="text-red-600">
                          Enter Description 2
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Description 3
                      </label>
                      <input
                        type="text"
                        {...register("point3", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Description 3"
                      />
                      {errors.point3 && (
                        <span className="text-red-600">
                          Enter Description 3
                        </span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Description 4
                      </label>
                      <input
                        type="text"
                        {...register("point4", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                        placeholder="Enter Description 4"
                      />
                      {errors.point4 && (
                        <span className="text-red-600">
                          Enter Description 4
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Image1
                      </label>
                      <input
                        type="file"
                        {...register("image1", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      />
                      {errors.image1 && (
                        <span className="text-red-600">Select Image1</span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        {" "}
                        Image2{" "}
                      </label>
                      <input
                        type="file"
                        {...register("image2", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      />
                      {errors.image2 && (
                        <span className="text-red-600">Select Image2</span>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-5">
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        Image3{" "}
                      </label>
                      <input
                        type="file"
                        {...register("image3", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      />
                      {errors.image3 && (
                        <span className="text-red-600">Select Image3</span>
                      )}
                    </div>
                    <div className="mb-5 w-1/2">
                      <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                        {" "}
                        Image4{" "}
                      </label>
                      <input
                        type="file"
                        {...register("image4", { required: true })}
                        className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      />
                      {errors.image4 && (
                        <span className="text-red-600">Select Image4</span>
                      )}
                    </div>
                  </div>
                  {submitState != true && (
                    <button
                      type="submit"
                      className="w-full rounded-lg bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 px-5 py-2.5 text-center text-sm font-medium text-white"
                    >
                      Submit
                    </button>
                  )}
                </form>
              </div>
            )}
          </Popup>
        </div>
      )}
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              No.
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              3 months Price
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              Yearly price
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              3 months DiscountAmount
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              Yearly DiscountAmount
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              3 months DiscountRate
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              Yearly DiscountAmount
            </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {couponData?.map((items, index) => {
            return (
              <tr
                className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                key={index}
              >
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  {index + 1}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  ₹{items.monthlyPriceNum}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  ₹{items.yearlyPriceNum}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  ₹{items.monthlyDiscountAmount}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  ₹{items.yearlyDiscountAmount}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  {`${Math.abs(items.monthlyDiscountRate).toFixed(2)}%`}
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  {Math.abs(items.yearlyDiscountRate).toFixed(2)}%
                </td>
                <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                  <div className="flex cursor-pointer gap-5">
                    <MdModeEditOutline
                      onClick={() => handleEditBtnClick(items)}
                    />
                    <MdOutlineRemoveRedEye
                      onClick={() => handleViewBtnClick(items)}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};
export default Subscription;