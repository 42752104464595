import React, {useState } from "react";
import { Routes, Route } from "react-router-dom";
import AdminLayout from "layouts/admin";
import TicketSupportContext from "views/admin/TicketSupport/components/TicketSupportContext";
import Login from "components/login/Login";
const App = () => {
  const [ticketSupport, setTicketSupport] = useState();
  const [ticketRenderState, setTicketRenderState] = useState("");
  const [viewRole, setViewRole] = useState();
  const [viewBtnState,setViewBtnState] = useState(false)
  const [notificationData,setNotificationData] = useState([])

  return (
    <TicketSupportContext.Provider value={{ticketSupport,ticketRenderState,viewRole,viewBtnState,notificationData,setTicketSupport,setTicketRenderState,setViewRole,setViewBtnState,setNotificationData}}>
      <Routes>
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </TicketSupportContext.Provider>
  );
};
export default App;