import axios from "axios";
import { authToken } from "../utils/auth"
import { baseUrl } from "../utils/domain";

const apiToken = authToken()
 const headers = {
   Authorization: `Bearer ${apiToken}`,
 };
const get = async (request) => {
  return await axios.get(`${baseUrl}/${request.path}`,{ headers });
};

const post = async (request) => {
  return (await axios.post(`${baseUrl}/${request.path}`, request.data)).data;
};

export { get, post };
