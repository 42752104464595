import React, { useState, useEffect } from "react";
import Card from "components/card";
import Chart from "react-apexcharts";
import { barChartOptionsWeeklyRevenue,barChartOptionsMonthlyRevenue,barChartOptionsYearlyRevenue } from "variables/charts";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { authToken } from "../../../../utils/auth";
import { baseUrl } from "../../../../utils/domain";
const RevenueChart = () => {
  const apiToken = authToken();
  const headers = {
    Authorization : `Bearer ${apiToken}`
  }
  const [chartData, setChartData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [timeRange, setTimeRange] = useState("week");
  const {register,watch,formState: { errors }} = useForm();
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const currentDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await fetch(`${baseUrl}/get-formData`,{headers});
        const users = await response.json();
        setAllUsers(users);
        processChartData(users, timeRange, startDate, endDate);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    fetchAllData();
  }, [timeRange]);
  useEffect(() => {
    if (allUsers.length > 0) {
      processChartData(allUsers, timeRange, startDate, endDate);
    }
  }, [startDate, endDate, allUsers, timeRange]);
  const processChartData = (users, range, startDate, endDate) => {
    const revenueMap = range === "week"? new Array(7).fill(0): range === "month"? new Array(5).fill(0): new Array(12).fill(0);
    const filteredUsers = users.filter((user) => {
      const createdAt = new Date(user.createdAt);
      return (
        (!startDate || new Date(startDate) <= createdAt) &&
        (!endDate || createdAt <= new Date(endDate))
      );
    });
    filteredUsers.forEach((user) => {
      const createdAt = new Date(user.createdAt);
      const revenue = parseInt(user.discountedPrice) || parseInt(user.price) || 0;
      if (range === "week") {
        const day = createdAt.getDay();
        revenueMap[day] += revenue;
      } else if (range === "month") {
        const date = createdAt.getDate();
        const startDayOfMonth = new Date(createdAt.getFullYear(),createdAt.getMonth(),1).getDay();
        const weekIndex = Math.floor((date + startDayOfMonth - 1) / 7);
        revenueMap[weekIndex] += revenue;
      } else if (range === "year") {
        const month = createdAt.getMonth();
        revenueMap[month] += revenue;
      }
    });
    const revenueArray = [{name: "Revenue",data: revenueMap.map((value) => (isNaN(value) ? 0 : value)),color: "#4318FF"}];
    const total = revenueMap.reduce((total, value) => total + value, 0);
    setTotalRevenue(total);
    setChartData(total > 0 ? revenueArray : []);
  };
  const getChartOptions = () => {
    switch (timeRange) {
      case "week":
        return barChartOptionsWeeklyRevenue;
      case "month":
        return barChartOptionsMonthlyRevenue;
      case "year":
        return barChartOptionsYearlyRevenue;
      default:
        return barChartOptionsWeeklyRevenue;
    }
  };
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 text-center">
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">Revenue {`- ₹${totalRevenue}`}</h2>
        <div className="flex items-center space-x-4">
          <select className="rounded border p-2" value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
            <option value="week">Weekly</option>
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
          </select>
          {(timeRange === "month" || timeRange === "year") && (
            <>
              <div className="grid gap-2">
                <label className="pr-10">Start Date</label>
                <input type="date" {...register("startDate")} />
              </div>
              To
              <div className="grid">
                <label className="pr-10">End Date</label>
                <input
                  type="date"
                  {...register("endDate", {
                    validate: {
                      notSame: (value) =>
                        value !== startDate ||
                        "End Date cannot be the same as Start Date",
                      notBeforeStart: (value) =>
                        !startDate ||
                        value > startDate ||
                        "End Date cannot be before Start Date",
                      notBeforeCurrentDate: (value) =>
                        value >= currentDate ||
                        "End Date cannot be before the current date",
                    },
                  })}
                />
              </div>
              {errors.endDate && <p>{errors.endDate.message}</p>}
            </>
          )}
        </div>
      </div>
      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          {chartData.length > 0 ? (
            <Chart options={getChartOptions()} series={chartData} type="bar" width="100%" height="100%"/>) 
            :
             (
             <p>No Data Available.</p>              
            )}
        </div>
      </div>
    </Card>
  );
};
export default RevenueChart;