import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { authToken } from "utils/auth";
import { baseUrl } from "utils/domain";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { useContext } from "react";
import TicketSupportContext from "../TicketSupport/components/TicketSupportContext";
const Notification = () => {
  const value = useContext(TicketSupportContext);
  let { notificationData } = value;
  const [specific_check, setspecific_check] = useState(false);
  const [notification_check, setNotification_check] = useState(false);
  const [selectedVariable, setSelectedVariable] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleAddVariable = () => {
    setDropdownVisible(!dropdownVisible);
    setSelectedVariable("");
    const currentValue = watch("title") || "";
    setValue("title", `${currentValue}${selectedVariable}`);
  };
  const [cities, setCities] = useState([]);
  const apiToken = authToken();
  const headers = {
    Authorization: `Bearer ${apiToken}`,
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [dateTimeString, setDateTimeString] = useState("");
  const scheduleDate = watch("scheduleDate");
  const time = watch("date");
  useEffect(() => {
    if (scheduleDate && time) {
      const combinedDateTime = `${scheduleDate}T${time}`;
      const dateTime = new Date(combinedDateTime).toISOString();
      setDateTimeString(dateTime);
    }
  }, [scheduleDate, time]);
  const image1 = watch("image1");
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("Description", data.Description);
    formData.append("city", data.city);
    formData.append("gender", data.gender);
    formData.append("marriedStatus", data.marriedStatus);
    // image changes
    if (data.image1) {
      formData.append("image1", data.image1[0]);
    }
    try {
      if (notificationData.length != 0) {
        if(notification_check){
          formData.append("scheduledTime", dateTimeString);
          const response = await axios.put(`${baseUrl}/update-notification/${notificationData._id}`,formData,{ headers }
          );
          if(response.status === 200){
            toast.success(response.data.message);
            reset();
          }    
        }else{
            const response = await axios.put(`${baseUrl}/update-notification/${notificationData._id}`,formData,{ headers });
            if (response.status === 200) {
              toast.success(response.data.message);
              reset();
            } 
        }
      } else if (notification_check) {
        formData.append("scheduledTime", dateTimeString);
        const response = await axios.post(`${baseUrl}/create-notification`,formData,{ headers });
        toast.success(response.data.message);
        reset();
      } else {
        const response = await axios.post(`${baseUrl}/send-notification`,formData,{ headers });
        toast.success(response.data.message);
        reset();
      }
    } catch (error) {
      toast.error("Internal Server error");
    }
  };
  useEffect(() => {
    axios.get(`${baseUrl}/get-cities`).then((res) => {
      setCities(res.data);
    });
       let { title, Description } = notificationData;
       setValue("title", title);
       setValue("Description", Description);
  }, []);
  const handleCheckboxChange = (e) => {
    setspecific_check(e.target.checked);
  };
  const handleScheduleNotification = (e) => {
    setNotification_check(e.target.checked);
  };
  return (
    <>
      <div>
        <h1 className="mb-4 font-bold">Send Push Notification</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-2">
            <label htmlFor="title" className="block pb-2 font-medium">Title</label>
            <input
              type="text"
              id="title"
              placeholder="Enter title"
              className="w-full rounded-lg border p-2"
              {...register("title", {
                required: "Title is required",
                minLength: {
                  value: 3,
                  message: "Title must be at least 3 characters long",
                },
                maxLength: {
                  value: 50,
                  message: "Title must be less than 30 characters long",
                },
              })}
            />
            {errors.title && (<p className="text-red-500">{errors.title.message}</p>)}
          </div>
          <button type="button" onClick={handleAddVariable} className="mb-1 rounded-md bg-blue-700 px-3 py-1 text-sm font-medium text-white me-2"> + Add Variable </button>
          {dropdownVisible == true && (
            <div>
              <select value={selectedVariable} onChange={(e) => setSelectedVariable(e.target.value)} className="w-10% rounded-lg border p-2">
                <option selected>Select Variable</option>
                <option value="{{firstname}}">firstname</option>
                <option value="{{lastname}}">lastname</option>
                <option value="{{city}}">city</option>
              </select>
            </div>
          )}
          <div className="py-2">
            <label className="block pb-2 font-medium">Description </label>
            <textarea
              rows="4"
              placeholder="Enter Description"
              className="w-full resize-none rounded-lg border p-2"
              {...register("Description", {
                required: "Description is required",
                minLength: {
                  value: 3,
                  message: "Description must be at least 3 character long",
                },
                maxLength: {
                  value: 50,
                  message: "Description must be less then 50 characters long",
                },
              })}
            />
            {errors.Description && (<p className="text-red-500">{errors.Description.message}</p>)}
          </div>
          <div className="pb-3">
            <label htmlFor="file" className="block pb-2 font-medium">Image (Optional) </label>
            <input
              type="file"
              id="file"
              className="w-full rounded-lg border bg-white p-2"
              {...register("image1")}
            />
          </div>
          <div>
            <input
              type="checkbox"
              id="specific-user"
              onChange={handleCheckboxChange}
              checked={specific_check}
            />
            <label htmlFor="specific-user" className="ml-3 cursor-pointer select-none" > Specific User </label>
            <input
              type="checkbox"
              id="notification-schedule"
              onChange={handleScheduleNotification}
              checked={notification_check}
              className="ml-96"
            />
            <label htmlFor="notification-schedule" className="ml-3 cursor-pointer select-none" > Schedule to send later </label>
          </div>
          {specific_check && (
            <>
              <div className="grid grid-cols-3 gap-3">
                <div>
                  <label htmlFor="file1" className="mb-1 block pt-3 font-medium" > Gender </label>
                  <select className="w-full rounded-lg border bg-white p-2" {...register("gender")}>
                    <option value=""> Select Gender </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="file2" className="mb-1 block pt-3 font-medium" > City </label>
                  <select
                    className="w-full rounded-lg border bg-white p-2"
                    {...register("city")}
                  >
                    <option value="" disabled selected> Select City </option>
                    {cities.map((items, index) => {
                      return (
                        <>
                          <option value={items} key={index}> {items} </option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <div className="flex-1">
                    <label htmlFor="file1" className="mb-1 block pt-3 font-medium" > Married Status </label>
                    <select
                      className="w-full rounded-lg border bg-white p-2"
                      {...register("marriedStatus")}
                    >
                      <option value="" disabled selected> Select Married status </option>
                      <option value="Unmarried">Unmarried</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </select>
                  </div>
                  <div className="flex-1"></div>
                </div>
              </div>
            </>
          )}
          {notification_check && (
            <>
              <div className="grid grid-cols-2 gap-8">
                <div>
                  <label htmlFor="file1" className="mb-1 block pt-3 font-medium">Select Date</label>
                  <input
                    type="date"
                    {...register("scheduleDate")}
                    min={new Date().toISOString().split("T")[0]}
                    className="w-full rounded-lg border bg-white p-2"
                  />
                </div>
                <div>
                  <label htmlFor="file2" className="mb-1 block pt-3 font-medium" > Select Time</label>
                  <input type="time" className="w-full rounded-lg border bg-white p-2" {...register("date")} />
                </div>
              </div>
            </>
          )}
          <button type="submit" className="w-1/10 mt-3 rounded-lg bg-blue-600 py-2 px-4 font-bold text-white hover:bg-blue-400" > Send Notification </button>
        </form>
        <ToastContainer />
      </div>
    </>
  );
};
export default Notification;