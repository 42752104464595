import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FiMinus } from "react-icons/fi";
import { HiOutlinePlus } from "react-icons/hi";
import { GoPlus } from "react-icons/go";
import { MdModeEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { useForm } from 'react-hook-form';
import { getAuthToken,authToken } from "../../../../utils/auth";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from '../../../../utils/domain';
const Faqs = () => {
  let token = getAuthToken();
  const apiToken = authToken()
  const headers = {
    Authorization : `Bearer ${apiToken}`
  }
  const [editItem, setEditItem] = useState(null);
  const [faqs,setFaqs] = useState([])
  const [toggleState, setToggleState] = useState({});
  const [renderComponent,setRenderComponent] = useState(false)
  const [couponTitleToggle,setCouponTitleToggle] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    axios.get(`${baseUrl}/get-faqs`, { headers }).then((res) => {
        setFaqs(res.data);
      });
  }, []);
    useEffect(() => {
      axios.get(`${baseUrl}/get-faqs`, { headers }).then((res) => {
          setFaqs(res.data);
        });
    }, [renderComponent]);
   const handleToggle = (index) => {
     setToggleState((prevState) => ({
       ...prevState,
       [index]: !prevState[index],
     }));
   };
   const onSubmit = (data,close) => {
    if(editItem){
      axios.put(`${baseUrl}/update-faqs/${editItem.question}`,data,{ headers }).then((res) => {
          toast.success(res.data.message);
          setEditItem(null);
          setRenderComponent(!renderComponent);
          setCouponTitleToggle(false)
          reset()
        });
    }else{
        axios.post(`${baseUrl}/post-faqs`, data, { headers }).then((res) => {
            toast.success(res.data.message);
            setRenderComponent(!renderComponent);
            setEditItem(null);
            reset();
            close();
          }).catch(() => {
            toast.error("Failed to create coupon");
          });
    }   
    };
    const handleFaqsEditClick = (item) =>{
      setCouponTitleToggle(true);
      setEditItem(item)
      setValue("question", item.question);
      setValue("description", item.description);
    }
    const handleFaqsDeleteClick = (item) =>{
        axios.delete(`${baseUrl}/delete-faqs/${item.question}`, { headers }).then((res) => {
            toast.success(res.data.message);
            setRenderComponent(!renderComponent);
          }).catch(() => {
            toast.error("Failed to delete coupon");
          });
    }
  return (
    <>
      {token.permissions.find((p) => p.name === "FAQ's")?.permissions.fullAccess && (
        <div className="flex justify-end ">
          <Popup
            open={!!editItem}
            trigger={
              <button
                type="button"
                className="my-8 mb-6 flex items-center gap-4 rounded-lg bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 px-5 py-2.5 text-center text-sm font-medium text-white me-2"
              ><GoPlus />Create FAQ'S
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="w-full rounded-lg bg-white p-4 shadow-lg border">
                <button
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 focus:outline-none"
                  onClick={()=>{ close(); setEditItem(null); reset(); setCouponTitleToggle(false)}}
                >
                  &times;
                </button>
                <h2 className="text-center text-4xl dark:text-white">
                  {couponTitleToggle === true ? "Update FAQ" : "Create FAQ"}
                </h2>
                <form className="w-full" onSubmit={handleSubmit((data) => onSubmit(data, close))}>
                  <div className="mb-5">
                    <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"> Enter Question </label>
                    <input
                      type="text"
                      {...register("question", { required: true })}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      placeholder="Enter Question"
                    />
                    {errors.code && (<span className="text-red-600 ">Enter Question</span>)}
                  </div>
                  <div className="mb-5">
                    <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Enter Description</label>
                    <textarea
                      rows={5}
                      type="text"
                      {...register("description", { required: true })}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                      placeholder="Enter Question"
                    />
                    {errors.code && (<span className="text-red-600 ">Enter Description</span>)}
                  </div>
                  <button type="submit" className="w-full rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white">Submit</button>
                </form>
              </div>
            )}
          </Popup>
        </div>
      )}
      {faqs.map((items, index) => {
        const isToggled = toggleState[index];
        return (
          <div className="mb-5 flex" key={index}>
            <div className="leading-1.5 flex w-full flex-col rounded-lg bg-customBlue p-3 shadow-lg">
              <div className="flex justify-between space-x-2 rtl:space-x-reverse">
                <span className="flex select-none gap-3 text-sm font-semibold text-gray-900 dark:text-white">
                  {`(${index + 1})  ${items?.question}`}
                </span>
                <div className="flex gap-4">
                  {token.permissions.find((p) => p.name === "FAQ's")?.permissions.fullAccess && (
                    <>
                      <MdModeEdit onClick={() => handleFaqsEditClick(items)} className="cursor-pointer" />
                      <MdDelete className="cursor-pointer" onClick={() => handleFaqsDeleteClick(items)} />
                    </>
                  )}
                  {isToggled ? (
                    <FiMinus onClick={() => handleToggle(index)} className="cursor-pointer"/>
                  ) : (
                    <HiOutlinePlus onClick={() => handleToggle(index)} className="cursor-pointer" />
                  )}
                </div>
              </div>
              <div>
                {isToggled && (
                  <>
                    <p className="select-none py-2.5 text-sm font-normal text-gray-900 dark:text-white"> {items?.description} </p>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <ToastContainer />
    </>
  );
}
export default Faqs