import React, { useEffect, useState } from "react";
import axios from "axios";
import { authToken } from "../../../utils/auth";
import { baseUrl } from "utils/domain";
const PastNotification = () => {
  const apiToken = authToken();
  const [PastNotification, setPastNotification] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const headers = {
    Authorization: `Bearer ${apiToken}`,
  };
  useEffect(() => {
    axios.get(`${baseUrl}/get-past-notification`, { headers }).then((res) => {
      setPastNotification(res.data);
    });
  }, []);
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <>
      <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">No.</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Date</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Time</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600">Title</th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600"> Description </th>
            <th className="px-6 py-3 text-xs font-bold tracking-wide text-gray-600"> Image </th>
          </tr>
        </thead>
        <tbody>
          {PastNotification.map((items, index) => (
            <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800" key={index} >
              <th className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white"> {index + 1} </th>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white"> {items.scheduledTime.split("T")[0]} </td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white"> {items.scheduledTime.split("T")[1].split(".")[0]} </td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white"> {items.title} </td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white"> {items.Description} </td>
              <td className="px-6 py-4 text-sm font-bold text-navy-700 dark:text-white">
                {items.image ? 
                <img
                  src={`${baseUrl + "/" + items.image}`}
                  alt="notification"
                  className="h-12 w-12 cursor-pointer rounded-md"
                  onClick={() =>handleImageClick(`${baseUrl + "/" + items.image}`)}
                /> : "-"
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="relative rounded-md bg-white p-4">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal} > &times; </button>
            <img src={selectedImage} alt="Enlarged notification" className="h-96 w-96" />
          </div>
        </div>
      )}
    </>
  );
};
export default PastNotification;